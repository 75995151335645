import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { wrapGrid } from 'animate-css-grid'
import { Collapse } from 'react-collapse'

import { Survivors } from './survivors'
import { Supporters } from './supporters'
import { Link, Icons, Observed } from '../../components'
import {
  useParams,
  useSearchParams,
  useSession,
  useEffect,
  useState,
  useLocation,
  useLayoutEffect,
  useRef,
  useOutletContext,
} from '../../hooks'
import { NotFound } from '..'

const abuseTypes = {
  survivors: {
    emotional: { Header: Survivors.EmotionalHeader, Blurb: Survivors.EmotionalBlurb, Copy: Survivors.Emotional },
    isolation: { Header: Survivors.IsolationHeader, Blurb: Survivors.IsolationBlurb, Copy: Survivors.Isolation },
    threats: { Header: Survivors.ThreatsHeader, Blurb: Survivors.ThreatsBlurb, Copy: Survivors.Threats },
    physical: { Header: Survivors.PhysicalHeader, Blurb: Survivors.PhysicalBlurb, Copy: Survivors.Physical },
    financial: { Header: Survivors.FinancialHeader, Blurb: Survivors.FinancialBlurb, Copy: Survivors.Financial },
    sexual: { Header: Survivors.SexualHeader, Blurb: Survivors.SexualBlurb, Copy: Survivors.Sexual },
    digital: { Header: Survivors.DigitalHeader, Blurb: Survivors.DigitalBlurb, Copy: Survivors.Digital },
  },
  supporters: {
    emotional: { Header: Supporters.EmotionalHeader, Blurb: Supporters.EmotionalBlurb, Copy: Supporters.Emotional },
    physical: { Header: Supporters.PhysicalHeader, Blurb: Supporters.PhysicalBlurb, Copy: Supporters.Physical },
    isolation: { Header: Supporters.IsolationHeader, Blurb: Supporters.IsolationBlurb, Copy: Supporters.Isolation },
    shame: { Header: Supporters.ShameHeader, Blurb: Supporters.ShameBlurb, Copy: Supporters.Shame },
    digital: { Header: Supporters.DigitalHeader, Blurb: Supporters.DigitalBlurb, Copy: Supporters.Digital },
  },
}

export function Landing() {
  const { scrollToMainEl } = useOutletContext()
  const transitionTimeout = 300
  const [inProp, setInProp] = useState(true)
  const grid = useRef(null)
  const { state } = useLocation()
  const { transitionFrom } = state || {}
  const [{ easyCode }] = useSession()
  const [{ supporter }] = useSearchParams()
  const isSupporter = supporter === 'true'
  const viewerType = isSupporter ? 'supporters' : 'survivors'
  const list = abuseTypes[viewerType]
  useLayoutEffect(() => {
    const { unwrapGrid } = wrapGrid(grid.current, { duration: transitionTimeout })
    return unwrapGrid
  }, [])
  if (!list) return <NotFound />
  return (
    <div className="landing">
      <CSSTransition
        in={inProp}
        timeout={{
          appear: 300,
          enter: 300,
          exit: transitionTimeout,
        }}
        appear={Boolean(transitionFrom)}
        enter={Boolean(transitionFrom)}
        exit
        classNames="transition"
      >
        <div
          ref={grid}
          className={cn('link-grid', [isSupporter ? 'five-item-grid' : 'seven-item-grid'], {
            'some-checked': easyCode,
          })}
        >
          {Object.entries(list).map(([type, { Blurb }]) => (
            <Link
              className={cn('biglink', type, {
                'transition-from': transitionFrom === type,
              })}
              timeout={transitionTimeout}
              transitionOut={() => {
                scrollToMainEl()
                document.getElementById('support').classList.remove('show-prompt')
                setInProp(false)
              }}
              to={type}
              key={type}
              id={type}
            >
              <div className="biglink__content">
                <Blurb
                  components={{
                    h1: 'h2',
                  }}
                />
              </div>
            </Link>
          ))}
        </div>
      </CSSTransition>
    </div>
  )
}

function Heading(props) {
  return <Observed Tag="h2" {...props} />
}

function Subheading(props) {
  return <Observed Tag="p" {...props} />
}

function NextSteps({ children, ...props }) {
  return (
    <ol {...props}>
      {children?.props?.children?.map((child, index) =>
        /* eslint-disable-next-line  react/no-array-index-key */
        child === '\n' ? null : <Observed key={index} Tag="li" {...child.props} />
      )}
    </ol>
  )
}

function Facts(props) {
  return <Observed Tag="div" {...props} className="facts" />
}

function P(props) {
  return <Observed Tag="p" {...props} />
}

export function Questionaire() {
  const [inProp, setInProp] = useState(true)
  const [{ supporter }] = useSearchParams()
  const { state } = useLocation()
  const { transitionFrom } = state || {}
  const isSupporter = supporter === 'true'
  const viewerType = isSupporter ? 'supporters' : 'survivors'
  const { type: currentType } = useParams()
  useEffect(() => {
    setInProp(true)
  }, [currentType])
  const current = abuseTypes[viewerType]?.[currentType]
  const [{ session }] = useSession()
  const someChecked = Object.values(session[currentType] || []).filter((value) => value).length
  if (!current) return <NotFound />
  const { Copy, Header } = current
  return (
    <CSSTransition
      in={inProp}
      appear
      enter
      exit
      timeout={{ enter: transitionFrom === 'section' ? 400 : 0 }}
      classNames={cn(
        {
          'transition-from-section': transitionFrom === 'section',
        },
        'transition'
      )}
    >
      <div
        className={cn({
          'transition-from-section': transitionFrom === 'section',
        })}
      >
        <div
          id={currentType}
          className={cn('questionaire', {
            'some-checked': someChecked,
          })}
        >
          <div
            className={cn('questionaire__header-transition-wrapper', {
              'transition-from': transitionFrom === currentType,
            })}
          >
            <div className="questionaire__header">
              <div className="questionaire__header-bg" />
              <div className="questionaire__header-wrapper">
                <Link to="/" state={{ transitionFrom: currentType }} className="questionaire__close-button">
                  <Icons.CircleClose />
                  <span className="sr-only">Return to main page</span>
                </Link>
                <div className="questionaire__header-content-wrapper">
                  <Header />
                </div>
              </div>
            </div>
          </div>
          <div className="questionaire__body">
            <Copy
              someChecked={someChecked}
              components={{
                h2: Heading,
                Collapse,
                Subheading,
                Facts,
                NextSteps,
              }}
            />
            <Observed Tag="div" className="questionaire__more-info">
              <div className="questionaire__more-info--icon">
                <Icons.MoreInfo />
              </div>
              <div className="questionaire__more-info--copy">
                <h3>Continue learning. Check out these resources:</h3>
                <p>
                  Power and Control Wheel, understand the cycle of abuse{' '}
                  <a href="https://awhl.org/chat">VAW Learning Network</a>
                </p>
                <p>
                  <b>Talk to us:</b> AWHL{' '}
                  <a className="questionaire__more-info--tel" href="tel:+18668630511">
                    1-866-863-0511
                  </a>
                  , <a href="https://awhl.org/chat">www.awhl.org/chat</a>
                </p>
              </div>
            </Observed>
          </div>
          <Observed
            className={cn('link-grid', 'link-grid--questionnaire-footer', [
              isSupporter ? 'four-item-grid' : 'six-item-grid',
            ])}
          >
            {Object.entries(abuseTypes[viewerType] ?? []).map(([type]) =>
              type === currentType ? null : (
                <Link
                  key={type}
                  id={type}
                  timeout={300}
                  transitionOut={() => {
                    setInProp(false)
                  }}
                  to={`/${type}`}
                  state={{ transitionFrom: 'section' }}
                  className={cn('biglink', type)}
                >
                  <div className="biglink__content">
                    <h2>{type}</h2>
                  </div>
                </Link>
              )
            )}
          </Observed>
        </div>
      </div>
    </CSSTransition>
  )
}
