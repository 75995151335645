import EmotionalHeader from './emotional-header.mdx'
import EmotionalBlurb from './emotional-blurb.mdx'
import Emotional from './emotional.mdx'
import IsolationHeader from './isolation-header.mdx'
import IsolationBlurb from './isolation-blurb.mdx'
import Isolation from './isolation.mdx'
import PhysicalHeader from './physical-header.mdx'
import PhysicalBlurb from './physical-blurb.mdx'
import Physical from './physical.mdx'
import ShameHeader from './shame-header.mdx'
import ShameBlurb from './shame-blurb.mdx'
import Shame from './shame.mdx'
import DigitalHeader from './digital-header.mdx'
import DigitalBlurb from './digital-blurb.mdx'
import Digital from './digital.mdx'

export const Supporters = {
  ShameHeader,
  ShameBlurb,
  Shame,
  EmotionalHeader,
  EmotionalBlurb,
  Emotional,
  IsolationHeader,
  IsolationBlurb,
  Isolation,
  PhysicalHeader,
  PhysicalBlurb,
  Physical,
  DigitalHeader,
  DigitalBlurb,
  Digital,
}
