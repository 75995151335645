import { useState, createContext, useContext, useMemo, useEffect } from 'react'

const SessionContext = createContext()

function shortenKey(key) {
  switch (key) {
    case 'shame':
      return 'h'
    default:
      return key.slice(0, 1)
  }
}

export function SessionProvider({ children }) {
  const initialState = {
    digital: {},
    emotional: {},
    financial: {},
    isolation: {},
    physical: {},
    sexual: {},
    shame: {},
    threats: {},
  }
  const [session, setSession] = useState(initialState)
  function resetSession() {
    setSession(initialState)
  }

  const [easyCode, setEasyCode] = useState('')
  useEffect(() => {
    setEasyCode(
      Object.entries(session)
        .map(([key, value]) => {
          const { length } = Object.values(value).filter((valueKey) => valueKey)
          return length > 0 ? [shortenKey(key), length].join('') : null
        })
        .join('')
    )
  }, [session])

  const value = useMemo(
    () => [
      { session, easyCode },
      { setSession, resetSession },
    ],
    [resetSession, setSession, session, easyCode]
  )
  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

export function useSession() {
  return useContext(SessionContext)
}
