/* eslint-disable */
import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import cn from 'classnames'

import { Icons } from '..'
import { useSession } from '../../hooks'
import { useCallback } from 'react'
import { quickExit } from '../../components/quick-exit'

const initializeAgent = (easyCode) => {
  if (typeof liveagent == 'undefined' || !liveagent) return
  if (easyCode) {
    liveagent.addCustomDetail('EasyCode', `Questionaire Easy Code: ${easyCode}`, true).saveToTranscript('body')
  }
  liveagent.init('https://d.la4-c2-ph2.salesforceliveagent.com/chat', '5723s000000Q161', '00D6A000001khAr')
  if (!window._laq) {
    window._laq = []
  }
  window._laq.push(() => {
    liveagent.showWhenOnline('5733s000000Q0K9', document.getElementById('liveagent_button_online_5733s000000Q0K9'))
    liveagent.showWhenOffline('5733s000000Q0K9', document.getElementById('liveagent_button_offline_5733s000000Q0K9'))
  })
}

const startChat = () => {
  liveagent.startChat('5733s000000Q0K9')
}

export function Chat() {
  const [isOpen, setIsOpen] = useState()
  const chatHasBeenOpened = useRef(false)
  const [{ easyCode }] = useSession()

  useLayoutEffect(() => {
    initializeAgent()
    return liveagent.disconnect
  }, [])

  const handleChatClick = useCallback(() => {
    if (!chatHasBeenOpened.current) {
      liveagent.disconnect()
      initializeAgent(easyCode)
      chatHasBeenOpened.current = true
    }
    startChat()
  }, [easyCode])

  return (
    <aside className={cn('chat', { 'some-checked': easyCode })}>
      <div className="chat__wrapper">
        <div aria-hidden={!isOpen} className={cn('chat__collapsible-wrapper', { open: isOpen })}>
          <div className="chat__phone">
            <span className="chat__phone-header">Call Us {easyCode ? 'Now' : ''}</span>
            <ul className="chat__phone--gta">
              <li>
                Gta <a href="tel:+14168630511">416.863.0511</a>
              </li>
              <li>
                Gta Tty <a href="tel:+14163468762">416.364.8762</a>
              </li>
            </ul>
            <ul className="chat__phone--toll-free">
              <li>
                Toll Free <a href="tel:+18668630511">1.866.863.0511</a>
              </li>
              <li>
                Toll Free Tty <a href="tel:+18663468762">1.866.364.8762</a>
              </li>
            </ul>
            <p className="chat__phone--text">
              #Safe <span>#7233</span>
              <span>(#7233) on your Bell, Rogers, Fido or Telus mobile phone</span>
            </p>
          </div>
          <div className={cn('chat__easy-code', { 'some-checked': easyCode })}>
            <span>easy code</span>
            <div>
              <span className="chat__easy-code--code">{easyCode}</span>
            </div>
          </div>
        </div>
        <div className="chat__buttons-wrapper">
          <button
            id="liveagent_button_online_5733s000000Q0K9"
            onClick={handleChatClick}
            type="button"
            className="chat__online-button"
          >
            <Icons.Chat />
            <span className="uppercase">Online chat</span>
          </button>
          <button
            id="liveagent_button_offline_5733s000000Q0K9"
            type="button"
            onClick={handleChatClick}
            className="chat__online-button"
            style={{ display: 'none' }}
          >
            <Icons.Chat />
            <span className="uppercase">Online chat</span>
          </button>
          <button type="button" onClick={quickExit} className="chat__quick-exit-button">
            <Icons.QuickExit />
            <span>Quick Exit</span>
          </button>
          <button type="button" onClick={() => setIsOpen(!isOpen)} className="chat__phone-button">
            {isOpen ? <Icons.CircleClose /> : <Icons.Phone />}
            {isOpen ? (
              <span className="uppercase">Close</span>
            ) : (
              <span className="uppercase">Call us {easyCode ? 'ASAP' : ''}</span>
            )}
          </button>
        </div>
      </div>
    </aside>
  )
}
