import cn from 'classnames'
import { Collapse } from 'react-collapse'

import { useSession, useParams } from '../../hooks'
import { Observed } from '..'

export function List({ children }) {
  return <ul className="list">{children}</ul>
}

export function ListItem({ heading, children }) {
  const { type } = useParams()
  const [{ session }, { setSession }] = useSession()
  const isOpen = Boolean(session[type]?.[heading])
  return (
    <Observed Tag="li" className={cn('list-item', [isOpen ? 'open' : 'closed'])}>
      <div className="list-item__control">
        <input
          onClick={(e) => {
            setSession((prevState) => {
              const newState = prevState
              newState[type][heading] = e.target.checked
              return { ...newState }
            })
          }}
          defaultChecked={isOpen}
          type="checkbox"
          id={heading}
        />
        <label htmlFor={heading}>{heading}</label>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="body">{children}</div>
      </Collapse>
    </Observed>
  )
}
