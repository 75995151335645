import { useLayoutEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import { isMobile } from 'react-device-detect'

Modal.setAppElement('body')

export function quickExit(e) {
  e.preventDefault()
  window.location.replace('https://google.ca/news')
  window.open('https://www.theweathernetwork.com/ca', '_blank').focus()
}

const modalStyle = {
  overlay: {
    zIndex: '999999',
    backdropFilter: 'blur(1px)',
  },
  content: {
    paddingTop: '100px',
    paddingBottom: '100px',
    paddingleft: '20px',
    paddingRight: '20px',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0px',
    border: 'none',
    textAlign: 'center',
    maxWidth: '712px',
    width: 'calc(100% - 40px)',
    boxSizing: 'border-box',
  },
}

export function QuickExitModal() {
  const [{ seen, open }, setModalState] = useState({ seen: false, open: false })
  const lastEscKeydownTime = useRef(0)
  useLayoutEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Escape') {
        const escKeydownTime = new Date()
        if (escKeydownTime - lastEscKeydownTime.current < 1000) {
          quickExit(e)
        } else {
          lastEscKeydownTime.current = escKeydownTime
        }
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    if (!seen && !open) {
      setModalState({ open: true, seen: false })
    }
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  })
  return (
    <Modal
      isOpen={open}
      style={modalStyle}
      onRequestClose={() => setModalState({ open: false, seen: true })}
      contentLabel="Example Modal"
    >
      <div className="quick-exit-modal__content">
        <h1>Leave this site safely</h1>
        <p>
          {isMobile
            ? 'You can quickly leave this website by clicking the “exit” button at the top of this page.'
            : 'You can quickly leave this website by clicking the “quick exit” button at the bottom of this page or by pressing the ESC (Escape) key twice on a laptop or desktop computer.'}
        </p>
        <p>To keep others from knowing you’ve visited this site, be sure to clear your recent browser history.</p>
        <button type="button" className="red" onClick={() => setModalState({ open: false, seen: true })}>
          OK
        </button>
      </div>
    </Modal>
  )
}
