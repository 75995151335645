import cn from 'classnames'

import { Button, Icons } from '..'
import { useMatch, useNavigate, useSearchParams, useSession, useParams, useLocation } from '../../hooks'

export function Support() {
  const location = useLocation()
  const isLanding = useMatch('/')
  const navigate = useNavigate()
  const { type: currentType } = useParams()
  const [{ supporter }] = useSearchParams()
  const isSupporter = supporter === 'true'
  const [, { resetSession }] = useSession()
  return (
    <header id="support" className={cn('support', { 'show-prompt': isLanding, 'is-supporter': isSupporter })}>
      <div className="support__container">
        <div className="support__content-wrapper">
          <p>Information is power, let us empower you</p>
          <p>
            {!isSupporter
              ? 'Before asking for help, one must realize they need it. Learn more about what qualifies as abuse.'
              : 'Learn more about what qualifies as abuse so you can speak confidently on the topic and support others.'}
          </p>
        </div>
        <div className="support__cta">
          <Button
            onClick={() => {
              resetSession()
              navigate(isSupporter ? '/' : '/?supporter=true', { state: { transitionFrom: currentType || false } })
            }}
            className="support__button"
          >
            <div className="bg" />
            {isSupporter ? <Icons.Hand /> : <Icons.Heart />}
            <span> {isSupporter ? 'Support for you?' : 'Support a loved one'}</span>
            <Icons.Right />
          </Button>
        </div>
      </div>
      <div className="support-prompt">
        <div className="support-prompt__container">
          <p>{isSupporter ? 'Select a type of abuse' : 'Select the type of abuse you identify with'}</p>
        </div>
      </div>
    </header>
  )
}
