import EmotionalHeader from './emotional-header.mdx'
import EmotionalBlurb from './emotional-blurb.mdx'
import Emotional from './emotional.mdx'
import IsolationHeader from './isolation-header.mdx'
import IsolationBlurb from './isolation-blurb.mdx'
import Isolation from './isolation.mdx'
import ThreatsHeader from './threats-header.mdx'
import ThreatsBlurb from './threats-blurb.mdx'
import Threats from './threats.mdx'
import PhysicalHeader from './physical-header.mdx'
import PhysicalBlurb from './physical-blurb.mdx'
import Physical from './physical.mdx'
import FinancialHeader from './financial-header.mdx'
import FinancialBlurb from './financial-blurb.mdx'
import Financial from './financial.mdx'
import SexualHeader from './sexual-header.mdx'
import SexualBlurb from './sexual-blurb.mdx'
import Sexual from './sexual.mdx'
import DigitalHeader from './digital-header.mdx'
import DigitalBlurb from './digital-blurb.mdx'
import Digital from './digital.mdx'

export const Survivors = {
  EmotionalHeader,
  EmotionalBlurb,
  Emotional,
  IsolationHeader,
  IsolationBlurb,
  Isolation,
  ThreatsHeader,
  ThreatsBlurb,
  Threats,
  PhysicalHeader,
  PhysicalBlurb,
  Physical,
  FinancialHeader,
  FinancialBlurb,
  Financial,
  SexualHeader,
  SexualBlurb,
  Sexual,
  DigitalHeader,
  DigitalBlurb,
  Digital,
}
