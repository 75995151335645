import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

export function usePageTracking() {
  const location = useLocation()
  useEffect(() => {
    ReactGA.initialize('UA-168750488-1')
    ReactGA.pageview(location.pathname + location.search)
  }, [location])
}
