import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { observe } from 'react-intersection-observer'

import { useLocation } from '../../hooks'

export function Observed({ children, Tag = 'div', className, ...props }) {
  const ref = useRef(false)
  const destroy = useRef(false)
  const { pathname } = useLocation()
  const [inView, setInView] = useState(false)
  useEffect(() => {
    if (ref.current) {
      destroy.current = observe(ref.current, (intersecting) => {
        if (intersecting) {
          setInView(true)
          destroy.current()
        }
      })
    }
    return () => {
      setInView(false)
      if (destroy.current) destroy.current()
    }
  }, [pathname])
  return (
    <Tag ref={ref} className={cn('observed', className, { inView })} {...props}>
      {children}
    </Tag>
  )
}
