import { useEffect, useRef, useCallback } from 'react'
import { Outlet } from 'react-router-dom'

import { Landing, Questionaire } from './pages'
import { Support, Footer, Chat, QuickExitModal } from './components'
import { useLocation, useRoutes, SessionProvider, usePageTracking } from './hooks'
import './App.css'

const routes = [
  {
    element: <Main />,
    children: [
      { index: true, element: <Landing /> },
      { path: ':type', element: <Questionaire /> },
    ],
  },
]

export function App() {
  usePageTracking()
  const body = useRoutes(routes)
  return body
}

function Main() {
  const { pathname, hash, search } = useLocation()
  const mainEl = useRef(null)
  const scrollToMainEl = useCallback(() => {
    if (!window) return
    if (window.scrollY < mainEl?.current.offsetTop) return
    window.scrollTo({ left: 0, top: mainEl.current.offsetTop })
  }, [])
  useEffect(() => {
    scrollToMainEl()
  }, [pathname, hash, search, scrollToMainEl])
  return (
    <SessionProvider>
      <Support />
      <main ref={mainEl} className="main">
        <Outlet context={{ scrollToMainEl }} />
      </main>
      <Footer />
      <Chat />
      <QuickExitModal />
    </SessionProvider>
  )
}
