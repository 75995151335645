import queryString from 'query-string'
import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom'

export const useSearchParams = () => {
  const [routerSearchParams, routerSetSearchParams] = useReactRouterSearchParams()
  const searchParams = queryString.parse(routerSearchParams.toString())
  const setSearchParams = (searchParamsToChange) => {
    const newSearchParams = { ...searchParams, ...searchParamsToChange }
    Object.keys(newSearchParams).forEach((key) => {
      if (!newSearchParams[key]) {
        delete newSearchParams[key]
      }
    })
    return routerSetSearchParams(newSearchParams)
  }
  return [searchParams, setSearchParams]
}
