import { useNavigate } from '../../hooks'

export function Button({ to, children, onClick, submit = false, ...props }) {
  const navigate = useNavigate()
  return (
    <button type={submit ? 'submit' : 'button'} onClick={() => (to ? navigate(to) : onClick())} {...props}>
      {children}
    </button>
  )
}
