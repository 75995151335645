import { Link as ReactRouterLink } from 'react-router-dom'
import cn from 'classnames'

import { useLocation, useNavigate, useCallback, useRef } from '../../hooks'

function ExternalLink({ children, href, label, ...props }) {
  return (
    <a href={href} label={label} {...props}>
      {children}
    </a>
  )
}

function RouterLink({ children, to, state, label, ...props }) {
  const { search } = useLocation()
  return (
    <ReactRouterLink to={`${to}${search}`} state={state} label={label} {...props}>
      {children}
    </ReactRouterLink>
  )
}

function TransitionLink({ children, transitionOut, timeout = 100, to, state = {}, label, className, ...props }) {
  const { search } = useLocation()
  const navigate = useNavigate()
  const linkRef = useRef(false)
  const onClick = useCallback(() => {
    transitionOut()
    linkRef.current.className = linkRef.current.className.concat(' transition-to')
    setTimeout(() => navigate(`${to}${search}`, { state }), timeout - 10)
  }, [to, search, timeout])
  return (
    <button
      ref={linkRef}
      type="button"
      className={cn('button--link', className)}
      onClick={onClick}
      label={label}
      {...props}
    >
      {children}
    </button>
  )
}

export function Link({ children, transitionOut, timeout, to, href, state, label, ...props }) {
  if (href) {
    return (
      <ExternalLink href={href} label={label} {...props}>
        {children}
      </ExternalLink>
    )
  }
  return transitionOut ? (
    <TransitionLink to={to} state={state} transitionOut={transitionOut} timeout={timeout} label={label} {...props}>
      {children}
    </TransitionLink>
  ) : (
    <RouterLink to={to} state={state} label={label} {...props}>
      {children}
    </RouterLink>
  )
}
