import { Link } from '../components'

export function NotFound() {
  return (
    <div className="container">
      <div>
        <h1>Not Found</h1>
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  )
}
