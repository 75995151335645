import { ReactComponent as OTF } from '../../static/otf.svg'

export function Footer() {
  return (
    <aside className="footer">
      <div className="footer__wrapper">
        <div className="footer__links">
          <p className="footer__links-header">Crisis Line</p>
          <div className="footer__links-wrapper">
            <ul>
              <li>
                <b className="uppercase">Gta</b> <a href="tel:+14168630511">416.863.0511</a>
              </li>
              <li>
                <b className="uppercase">Gta Tty</b> <a href="tel:+14163468762">416.364.8762</a>
              </li>
            </ul>
            <ul>
              <li>
                <b className="uppercase">Toll-Free</b> <a href="tel:+18668630511">1.866.863.0511</a>
              </li>
              <li>
                <b className="uppercase">Toll-Free tty</b> <a href="tel:+18663468762">1.866.364.8762</a>
              </li>
            </ul>
            <ul>
              <li>
                <b className="uppercase">#SAFE</b> (#7233) on your Bell, Rogers, Fido or Telus mobile phone
              </li>
              <li>
                <b>Seniors Safety Line</b> <a href="tel:+18662991011">1-866-299-1011</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__logo self-center">
          <OTF />
          <span className="sr-only">Ontario Trillium Foundation: an agency of the government of Ontario</span>
        </div>
      </div>
    </aside>
  )
}
