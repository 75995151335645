import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faCaretRight,
  faCaretLeft,
  faCaretUp,
  faCaretDown,
  faMessages,
  faHandHeart,
  faCircleXmark,
  faMobileScreenButton,
  faMessageQuestion,
  faOctagonXmark,
} from '@fortawesome/pro-solid-svg-icons'

export function QuickExit(props) {
  return <FontAwesomeIcon icon={faOctagonXmark} {...props} />
}

export function Heart(props) {
  return <FontAwesomeIcon icon={faHeart} {...props} />
}

export function CircleClose(props) {
  return <FontAwesomeIcon icon={faCircleXmark} {...props} />
}

export function Hand(props) {
  return <FontAwesomeIcon icon={faHandHeart} {...props} />
}

export function Right(props) {
  return <FontAwesomeIcon icon={faCaretRight} {...props} />
}

export function Left(props) {
  return <FontAwesomeIcon icon={faCaretLeft} {...props} />
}

export function Up(props) {
  return <FontAwesomeIcon icon={faCaretUp} {...props} />
}

export function Down(props) {
  return <FontAwesomeIcon icon={faCaretDown} {...props} />
}

export function Chat(props) {
  return <FontAwesomeIcon icon={faMessages} {...props} />
}

export function Phone(props) {
  return <FontAwesomeIcon icon={faMobileScreenButton} {...props} />
}

export function MoreInfo(props) {
  return <FontAwesomeIcon icon={faMessageQuestion} {...props} />
}
